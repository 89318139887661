import { WebsiteEntity } from "@foudroyer/interfaces"

export const indexation_website_bad_setup_on_google_modal_set_open =
  "indexation_website_bad_setup_on_google_modal/set_open"
export interface indexation_website_bad_setup_on_google_modal_set_open_action {
  type: typeof indexation_website_bad_setup_on_google_modal_set_open
  payload: { website_that_has_bad_setup: WebsiteEntity }
}

export const indexation_website_bad_setup_on_google_modal_store_websites_user_owns =
  "indexation_website_bad_setup_on_google_modal/store_websites_user_owns"
export interface indexation_website_bad_setup_on_google_modal_store_websites_user_owns_action {
  type: typeof indexation_website_bad_setup_on_google_modal_store_websites_user_owns
  payload: { all_websites_user_owns: WebsiteEntity[] }
}

export const indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching =
  "indexation_website_bad_setup_on_google_modal/set_all_websites_user_owns_fetching"
export interface indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching_action {
  type: typeof indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching
  payload: { all_websites_user_owns_fetching: boolean }
}

export const indexation_website_bad_setup_on_google_modal_set_update_fetching =
  "indexation_website_bad_setup_on_google_modal/set_update_fetching"
export interface indexation_website_bad_setup_on_google_modal_set_update_fetching_action {
  type: typeof indexation_website_bad_setup_on_google_modal_set_update_fetching
  payload: { update_fetching: boolean }
}

export const indexation_website_bad_setup_on_google_modal_store_selected_website_to_update =
  "indexation_website_bad_setup_on_google_modal/store_selected_website_to_update"
export interface indexation_website_bad_setup_on_google_modal_store_selected_website_to_update_action {
  type: typeof indexation_website_bad_setup_on_google_modal_store_selected_website_to_update
  payload: { selected_website_to_update: string }
}

export const indexation_website_bad_setup_on_google_modal_set_reasons_why_my_website_cannot_be_checked_by_google =
  "indexation_website_bad_setup_on_google_modal/set_reasons_why_my_website_cannot_be_checked_by_google"
export interface indexation_website_bad_setup_on_google_modal_set_reasons_why_my_website_cannot_be_checked_by_google_action {
  type: typeof indexation_website_bad_setup_on_google_modal_set_reasons_why_my_website_cannot_be_checked_by_google
  payload: {
    google_search_console_domain: string
    page_from_sitemap_try_to_index_this_page: string
  }
}

export const indexation_website_bad_setup_on_google_modal_set_update_website_response_error =
  "indexation_website_bad_setup_on_google_modal/set_update_website_response_error"
export interface indexation_website_bad_setup_on_google_modal_set_update_website_response_error_action {
  type: typeof indexation_website_bad_setup_on_google_modal_set_update_website_response_error
  payload: { update_website_response_error: string | null }
}

export type Types =
  | indexation_website_bad_setup_on_google_modal_set_open_action
  | indexation_website_bad_setup_on_google_modal_store_websites_user_owns_action
  | indexation_website_bad_setup_on_google_modal_store_selected_website_to_update_action
  | indexation_website_bad_setup_on_google_modal_set_update_website_response_error_action
  | indexation_website_bad_setup_on_google_modal_set_reasons_why_my_website_cannot_be_checked_by_google_action
  | indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching_action
  | indexation_website_bad_setup_on_google_modal_set_update_fetching_action
