import { ErrorEntity, WebsiteEntity } from "@foudroyer/interfaces"
import { ThunkAction } from "redux-thunk"
import { ModalKeys } from "../../entities/ModalEntity"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

const indexation_website_bad_setup_on_google_modal_set_open = (params: {
  website_that_has_bad_setup: WebsiteEntity
}): types.Types => ({
  type: types.indexation_website_bad_setup_on_google_modal_set_open,
  payload: params,
})

const indexation_website_bad_setup_on_google_modal_store_websites_user_owns =
  (params: { websites: WebsiteEntity[] }): types.Types => ({
    type: types.indexation_website_bad_setup_on_google_modal_store_websites_user_owns,
    payload: {
      all_websites_user_owns: params.websites,
    },
  })

const indexation_website_bad_setup_on_google_modal_set_update_fetching =
  (params: { update_fetching: boolean }): types.Types => ({
    type: types.indexation_website_bad_setup_on_google_modal_set_update_fetching,
    payload: {
      update_fetching: params.update_fetching,
    },
  })

export const indexation_website_bad_setup_on_google_modal_store_selected_website_to_update =
  (params: { selected_website_to_update: string }): types.Types => ({
    type: types.indexation_website_bad_setup_on_google_modal_store_selected_website_to_update,
    payload: {
      selected_website_to_update: params.selected_website_to_update,
    },
  })

const indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching =
  (params: { all_websites_user_owns_fetching: boolean }): types.Types => ({
    type: types.indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching,
    payload: {
      all_websites_user_owns_fetching: params.all_websites_user_owns_fetching,
    },
  })

const indexation_website_bad_setup_on_google_modal_set_reasons_why_my_website_cannot_be_checked_by_google =
  (params: {
    google_search_console_domain: string
    page_from_sitemap_try_to_index_this_page: string
  }): types.Types => ({
    type: types.indexation_website_bad_setup_on_google_modal_set_reasons_why_my_website_cannot_be_checked_by_google,
    payload: {
      ...params,
    },
  })

export const $indexation_website_bad_setup_on_google_modal_close =
  (): ThunkAction<any, RootState, any, any> => async (dispatch) => {
    dispatch(actions.modal.$close())
  }

export const $indexation_website_bad_setup_on_google_modal_refresh_websites_user_owns =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di } = getState()

    dispatch(
      indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching(
        {
          all_websites_user_owns_fetching: true,
        }
      )
    )

    const websites_user_owns = await di.api.get<WebsiteEntity[]>(
      `/websites/google`
    )

    dispatch(
      indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching(
        {
          all_websites_user_owns_fetching: false,
        }
      )
    )

    /**
     * If there is an error, display it
     */
    if (websites_user_owns.error) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: websites_user_owns.code,
        })
      )
    }

    /**
     * Store in the reducer all the websites the user owns
     */
    dispatch(
      indexation_website_bad_setup_on_google_modal_store_websites_user_owns({
        websites: websites_user_owns.body,
      })
    )
  }

export const $indexation_website_bad_setup_on_google_modal_open =
  (params: { website_id: string }): ThunkAction<any, RootState, any, any> =>
  async (dispatch, getState) => {
    const { di, indexation_website_bad_setup_on_google_modal, websites } =
      getState()

    const website_that_has_bad_setup = websites.map.get(params.website_id)

    if (!website_that_has_bad_setup) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: ErrorEntity.WEBSITE_NOT_FOUND,
        })
      )
    }

    /**
     * Store in the reducer the website that has bad setup
     */
    dispatch(
      indexation_website_bad_setup_on_google_modal_set_open({
        website_that_has_bad_setup,
      })
    )

    /**
     * Open the modal with url change
     */
    dispatch(
      actions.modal.$open(
        ModalKeys["indexation-website-bad-setup-on-google-modal"]
      )
    )

    /**
     * Fetch why_my_website_cannot_be_checked_by_google
     */
    const why_my_website_cannot_be_checked_by_google = await di.api.post<{
      google_search_console_domain: string
      page_from_sitemap_try_to_index_this_page: string
    }>(`/websites/why-my-website-cannot-be-checked-by-google`, {
      website_id: params.website_id,
    })

    if (why_my_website_cannot_be_checked_by_google.error) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: why_my_website_cannot_be_checked_by_google.code,
        })
      )
    }

    dispatch(
      indexation_website_bad_setup_on_google_modal_set_reasons_why_my_website_cannot_be_checked_by_google(
        why_my_website_cannot_be_checked_by_google.body
      )
    )

    dispatch(
      $indexation_website_bad_setup_on_google_modal_refresh_websites_user_owns()
    )
  }

export const $indexation_website_bad_setup_on_google_modal_update_website =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di, indexation_website_bad_setup_on_google_modal, websites } =
      getState()

    const website_that_has_bad_setup =
      indexation_website_bad_setup_on_google_modal.website_that_has_bad_setup

    if (!website_that_has_bad_setup) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: ErrorEntity.WEBSITE_NOT_FOUND,
        })
      )
    }

    dispatch(
      indexation_website_bad_setup_on_google_modal_set_update_fetching({
        update_fetching: true,
      })
    )

    const website_update_response = await di.api.put(
      `/websites/update-search-console-domain`,
      {
        website_id: website_that_has_bad_setup.id,
        new_google_search_console_domain:
          indexation_website_bad_setup_on_google_modal.selected_website_to_update,
      }
    )

    dispatch(
      indexation_website_bad_setup_on_google_modal_set_update_fetching({
        update_fetching: false,
      })
    )

    if (website_update_response.error) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: website_update_response.code,
        })
      )
    }

    dispatch(
      actions.modal.$open(
        ModalKeys["indexation-website-bad-setup-on-google-success-modal"]
      )
    )
  }
