import { WebsiteEntity } from "@foudroyer/interfaces"
import * as types from "./types"

interface State {
  website_that_has_bad_setup: WebsiteEntity | null
  selected_website_to_update: WebsiteEntity | null
  all_websites_user_owns: WebsiteEntity[]
  all_websites_user_owns_fetching: boolean
  update_website_response_error: string | null
  reasons_why_my_website_cannot_be_checked_by_google: {
    google_search_console_domain: string
    page_from_sitemap_try_to_index_this_page: string
  }
  update_fetching: boolean
}

const initialState: State = {
  website_that_has_bad_setup: null,
  selected_website_to_update: null,
  all_websites_user_owns: [],
  reasons_why_my_website_cannot_be_checked_by_google: {
    google_search_console_domain: "",
    page_from_sitemap_try_to_index_this_page: "",
  },
  all_websites_user_owns_fetching: false,
  update_website_response_error: null,
  update_fetching: false,
}

export function indexation_website_bad_setup_on_google_modal_reducer(
  state = initialState,
  action: types.Types
): State {
  if (
    action.type === types.indexation_website_bad_setup_on_google_modal_set_open
  ) {
    return {
      ...initialState,
      website_that_has_bad_setup: action.payload.website_that_has_bad_setup,
    }
  }

  if (
    action.type ===
    types.indexation_website_bad_setup_on_google_modal_store_websites_user_owns
  ) {
    return {
      ...state,
      all_websites_user_owns: action.payload.all_websites_user_owns,
    }
  }

  if (
    action.type ===
    types.indexation_website_bad_setup_on_google_modal_store_selected_website_to_update
  ) {
    return {
      ...state,
      selected_website_to_update: action.payload.selected_website_to_update,
    }
  }

  if (
    action.type ===
    types.indexation_website_bad_setup_on_google_modal_set_update_website_response_error
  ) {
    return {
      ...state,
      update_website_response_error:
        action.payload.update_website_response_error,
    }
  }

  if (
    action.type ===
    types.indexation_website_bad_setup_on_google_modal_set_all_websites_user_owns_fetching
  ) {
    return {
      ...state,
      all_websites_user_owns_fetching:
        action.payload.all_websites_user_owns_fetching,
    }
  }

  if (
    action.type ===
    types.indexation_website_bad_setup_on_google_modal_set_update_fetching
  ) {
    return {
      ...state,
      update_fetching: action.payload.update_fetching,
    }
  }

  if (
    action.type ===
    types.indexation_website_bad_setup_on_google_modal_set_reasons_why_my_website_cannot_be_checked_by_google
  ) {
    return {
      ...state,
      reasons_why_my_website_cannot_be_checked_by_google: {
        ...action.payload,
      },
    }
  }

  return state
}
